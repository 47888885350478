/* eslint-disable no-undef */
import { debounce } from '@propertypal/shared/src/hooks/useDebounce';
import { AdSlot } from '@propertypal/shared/src/types/ads';
import { fetchApsBids } from './ads/apstag';
import { addGoogleSlot, refreshGoogleTags } from './ads/googletag';
import { fetchPrebid, pushPrebidSlot, removePrebidSlot } from './ads/prebid';

const AD_UNITS: AdSlot[] = [];
const NC = '1040099';

/**
 * Display or refresh ad units that have been configured
 * and are ready for display.
 */
const performRefresh = debounce(() => {
  window.googletag.cmd.push(() => {
    const refreshSlots: any[] = [];

    AD_UNITS.forEach((unit) => {
      // Prepare new units
      if (unit.ready && !unit.activated && !unit.refresh) {
        unit.activated = true;
        refreshSlots.push(unit.slot);
      }

      // Identify units ready for refresh
      if (unit.refresh) {
        refreshSlots.push(unit.slot);
        unit.refresh = false;
      }
    });

    // Activate/refresh ad units
    if (refreshSlots.length > 0) {
      refreshGoogleTags(refreshSlots);
    }
  });
}, 50);

/**
 * Remove an ad unit from the page.
 * @param slotName the ad unit's slot name
 */
export function removeUnit(slotName: string) {
  const index = AD_UNITS.findIndex((unit) => {
    return unit.slotName === slotName;
  });

  if (index >= 0) {
    if (AD_UNITS[index].slot) {
      removePrebidSlot(AD_UNITS[index].code);
      window.googletag.cmd.push(() => {
        window.googletag.destroySlots([AD_UNITS[index].slot]);
      });
    }

    AD_UNITS.splice(index, 1);
  }
}

/**
 * Mark the specified ad unit as active and display it.
 * @param slotName  the ad unit's slot name
 */
export const activateUnit = (slotName: string) => {
  const unit = AD_UNITS.find((u) => u.slotName === slotName);
  if (unit) {
    unit.ready = true;
    performRefresh();
  }
};

/**
 * Refresh the specified ad unit.
 * @param slotName the ad unit's slot name
 */
export const refreshUnit = (slotName: string) => {
  const unit = AD_UNITS.find((u) => u.slotName === slotName);
  if (unit) {
    unit.refresh = true;
    performRefresh();
  }
};

/**
 * Debounce the bids requests to allow multiple ad units to
 * request bids simultaneously.
 */
const requestBidsDebounced = debounce(() => {
  const adUnitCodes = AD_UNITS.map((unit) => unit.code);
  const apsSlots = AD_UNITS.map((unit) => ({
    slotID: unit.slotID,
    sizes: unit.sizes.filter((size) => typeof size !== 'string'),
    slotName: unit.slotName,
  })).filter((slot) => slot.sizes.length > 0);
  return Promise.all([fetchPrebid(adUnitCodes), fetchApsBids(apsSlots)]);
}, 50);

/**
 * Request bids from prebid bidders and Amazon APS, this
 * async function will return once all bidders have returend
 * bids or the timeout expires.
 *
 * @returns a Promise
 */
export const requestBids = async (): Promise<any> => {
  return requestBidsDebounced();
};

/**
 * Register an ad unit on the page for the specified ad unit code. If the ad unit code name is not
 * prefixed with a network code, the default PropertyPal network code will be prepended. This
 * allows ad units with other networks to be registered if required by simply supplying the fill
 * ad unit path.
 *
 * @param code ad unit code
 * @param slotName ad unit slot name
 * @param sizes valid sizes
 * @param ybActive whether yieldbird PriceGenius should be activated for this unit
 */
export const registerUnit = (
  code: string,
  slotName: string,
  elementId: string,
  sizes: AdSlot['sizes'],
  ybActive: boolean,
  targeting?: any,
) => {
  window.googletag.cmd.push(() => {
    const fullCode = code.startsWith('/') ? code : `/${NC}/${code}`;
    AD_UNITS.push({
      code: fullCode,
      slotID: elementId,
      sizes,
      slotName,
      slot: addGoogleSlot(fullCode, sizes, elementId, targeting, ybActive),
      ready: false,
      activated: false,
      refresh: false,
      ybActive,
      targeting,
    });
  });
};

/**
 * Prepare an ad unit by registering it, on the page and with prebid, and request bids for the ad unit,
 * this function is asynchronous and will return a promise that will resolve once bids are returned
 * or the timeout expires.
 *
 * @param config prebid config key
 * @param slotName  ad unit slot name
 * @param sizes valid sizes
 * @returns a promise
 */
export const prepareUnit = (config: string, slotName: string, elementId: string, sizes: AdSlot['sizes']) => {
  const code = `/${NC}/${slotName}`;
  const found = AD_UNITS.find((unit) => unit.code === code);
  if (!found) {
    registerUnit(code, slotName, elementId, sizes, true);
    pushPrebidSlot(config, code, sizes);
  }
};
