import { deleteFavourite, saveFavourite } from '@propertypal/shared/src/reducers/favourites';
import { useAppDispatch, AppDispatch } from '@propertypal/shared/src/reducers/store';
import router from 'next/router';
import { useCallback } from 'react';

export const favouriteProperty =
  (dispatch: AppDispatch, loggedIn: boolean, propertyId?: number) =>
  async (favourited: boolean, mPropertyId: number = 0) => {
    const id = propertyId !== undefined ? propertyId : mPropertyId;

    if (!loggedIn) {
      const sourcePath = `shortlist?update=${id}&sourcePath=${window.location.pathname}`;
      await router.push(`/login?sourcePath=/${encodeURIComponent(sourcePath)}`);

      return false;
    }

    if (favourited) {
      return dispatch(saveFavourite(id));
    }

    return dispatch(deleteFavourite(id));
  };

const usePropertyFavourite = (loggedIn: boolean, propertyId?: number) => {
  const dispatch = useAppDispatch();

  return useCallback(() => {
    return favouriteProperty(dispatch, loggedIn, propertyId);
  }, [loggedIn]);
};

export default usePropertyFavourite;
