import { laptopMediumMax, mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div<{ $padding?: number }>`
  display: flex;
  flex-direction: row;
  padding: 20px ${({ $padding }) => $padding || '30'}px 0;
  height: 37px;

  @media (${laptopMediumMax}) {
    padding: 20px 15px 0;
  }

  @media (${mobileXXLargeMax}) {
    display: none;
  }
`;

export const BackLink = styled.button`
  font-size: 12px;
  color: ${(props) => props.theme.primary};
  margin-right: 20px;
  cursor: pointer;
  transition: 300ms all;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    color: ${(props) => props.theme.textLight};
  }
`;

export const Crumbs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`;

export const Crumb = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.textLight};
  margin-right: 3px;

  &:hover {
    color: ${(props) => props.theme.textDark};
  }

  &:last-child {
    font-weight: bold;
    color: ${(props) => props.theme.textDark};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
