/* eslint-disable */
export const IAB_GOOGLE_ADVERTISING_CODE = 755;

let lastConsents;

/**
 * Determine whether a specific set of consents has been granted by
 * the user via the CMP.
 *
 * @param {*} consents an array of IAM consent codes
 * @returns true if consent given
 */
export const hasConsents = (purpose, vendor) => {
  if (!lastConsents) return false;
  let consentsFound = true;
  purpose.forEach((value) => {
    if (!lastConsents.purpose.consents[value]) {
      consentsFound = false;
    }
  });
  vendor.forEach((value) => {
    if (!lastConsents.vendor.consents[value]) {
      consentsFound = false;
    }
  });
  return consentsFound;
};

/**
 * Determine if the CMP has been setup, if false, this typically will
 * indicate a bot user where the CMP is ignored.
 *
 * @returns true if the CMP is live
 */
export const isCmpLive = () => {
  return typeof window !== 'undefined' && typeof window.__tcfapi === 'function';
};

const performOnConsent = (handler, purpose, vendor, attempt) => {
  if (attempt > 50) {
    console.log('Failed to initiate CMP after 500ms');
    return;
  }
  if (isCmpLive()) {
    window.__tcfapi('addEventListener', 2, (tcData, listenerSuccess) => {
      if (listenerSuccess && (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded')) {
        lastConsents = tcData;

        if (hasConsents(purpose, vendor)) handler();
      }

      // Cleanup event listener
      if (tcData.eventStatus === 'tcloaded') {
        window.__tcfapi('removeEventListener', 2, (success) => {}, tcData.listenerId);
      }
    });
  } else {
    setTimeout(() => {
      performOnConsent(handler, purpose, vendor, attempt + 1);
    }, 10);
  }
};

/**
 * Execute a handler function upon consent being granted.
 *
 * @param {*} handler the handler
 * @param  {...any} consents an array of IAM consent codes
 */
export const onConsent = (handler, purpose, vendor) => {
  // Run the handler if useractioncomplete has already executed
  if (hasConsents(purpose, vendor)) {
    handler();
  }
  // Run the handler after useractioncomplete
  else {
    performOnConsent(handler, purpose, vendor, 1);
  }
};
