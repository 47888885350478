/* eslint-disable no-underscore-dangle */
import { DependencyList, EffectCallback, useEffect } from 'react';
import { initApsTag } from '../services/ads/apstag';
import { initGoogleTag } from '../services/ads/googletag';
import { initPrebid } from '../services/ads/prebid';

declare global {
  interface Window {
    googletag: any;
    pbjs: any;
    apstag: any;
    _comscore: any;
  }
}

const useGpt = (callback: EffectCallback, deps?: DependencyList) => {
  useEffect(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];
    window.pbjs = window.pbjs || {};
    window.pbjs.que = window.pbjs.que || [];
    window._comscore = window._comscore || [];

    initApsTag();
    initPrebid();
    initGoogleTag();
  }, []);

  useEffect(callback, [...(deps || [])]);
};

export default useGpt;
