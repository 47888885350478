/* eslint-disable no-undef */
import useDebounce from '@propertypal/shared/src/hooks/useDebounce';
import useEventListener from '@propertypal/shared/src/hooks/useEventListener';
import 'intersection-observer';
import React, { CSSProperties, FunctionComponent, useEffect, useRef, useState } from 'react';
import { onConsent } from '../../services/ads/consentListener';
import { addListener, removeListener } from '../../services/ads/googletag';
import { activateUnit, registerUnit, removeUnit, prepareUnit, refreshUnit, requestBids } from '../../services/adTags';
import { Container, Content, Declaration } from './AdBox.style';

interface Props {
  id: string;
  config: string;
  url?: string;
  sizes: Array<string | number[]>;
  position?: string;
  hideUnder?: number;
  hideOver?: number;
  disabledPointerEvents?: boolean;
  testID?: string;

  /**
   * The ad unit code to display if this ad is un-filled
   *  this is the full code including the network code, which
   *  allows the recovery unit to be delivered via a different
   *  GAM account if required
   */
  recoveryUnitCode?: string;

  /**
   * Unit code to gradually migrate traffic to, when specified
   * a percentage of traffic will use the specified code instead of
   * the main code.
   */
  abTestCode?: string;

  /**
   * The percentage of impressions that should use the migrationUnitCode
   * instead of teh default unit code
   */
  abTestPercentage?: number;

  /**
   * Styles to apply to the ad container
   */
  containerStyle?: CSSProperties;

  /**
   * Additional styles apply to the add container
   * after the slot renders an ad and is not blank
   */
  renderedStyle?: CSSProperties;

  /**
   * Activate interscroller ad style for rendered ads that are at
   * least INTERSCROLLER_MIN_AD_HEIGHT tall
   */
  interscroller?: boolean;

  /**
   * Override the interscroller style.
   */
  interscrollerStyle?: CSSProperties;

  /**
   * Additional top position offset for interscroller ads, use
   * this setting if to move the ad down if other fixed position
   *  content could overlay the ad when in the viewport
   */
  interscrollerOffset?: number;

  /**
   * Defines whether the ad unit should be auto refreshed
   * after a period of time (on user interaction with the page)
   */
  refresh?: boolean;

  /**
   * Whether to perform timed refreshes, i.e. refreshes that
   * happen after a interval even with zero user interaction
   * also requires `refresh` to be true to take effect
   */
  timedRefresh?: boolean;

  /**
   * Override the default lazy loading display margin
   */
  displayMargin?: number;

  /**
   * Override the default lazy loading bids margin
   */
  bidsMargin?: number;

  /**
   * Is the ad currently obscured, e.g. overlayed
   * by a modal window? Ads will not
   * refresh until they have been un-obscured and
   * within the viewport for at least MIN_TIME_IN_VIEW milliseconds
   */
  obscured?: boolean;

  /**
   * If true, ad units will collapse to the height of the rendered
   * advert, otherwise the ad space will remain the height of the
   * largest possible advert the unit can handle.
   */
  collapse?: boolean;

  /**
   * Defines wiether the 'advertisement' declaration should be added before the ad
   */
  declaration?: boolean;
}

// Minimum amount of time an ad must be in-view for
//  before it is eligible for a refresh
const MIN_TIME_IN_VIEW = 30000;

// Minimum interval after the viewable event
//  before an ad is eligible for refresh
const MIN_REFRESH_DELAY = 60000;

// Minimum interval after the viewable event
//  before a timed refresh can occur
const MIN_TIMED_REFRESH_DELAY = 60000;

// Maximum time with no user-interaction before refreshing is disabled
const USER_IDLE_TIMEOUT = 120000;

// Maximum time before requesting new bids for an ad slot
const BIDS_TIMEOUT = 58000;

// Maximum number of refreshes per unit
const MAX_REFRESHES = 25;

// Number of pixels away from the viewport and ad must come before being displayed
const DISPLAY_MARGIN = 300;

// The number of pixes in addition to DISPLAY_MARGIN that the ad must come
//  to the viewport before the bids are requested
const BIDS_MARGIN = 800;

// Defines the number of pixles of padding to apply to the
//  interscroller container
const INTERSCROLLER_PADDING = 25;

// The height of the interscroller channel
const INTERSCROLLER_CHANNEL_HEIGHT = 600;

const INTERSCROLLER_STYLE: CSSProperties = {
  borderStyle: 'solid',
  borderColor: '#efefef',
  borderWidth: '2px 0',
  paddingTop: INTERSCROLLER_PADDING,
  paddingBottom: INTERSCROLLER_PADDING,
};

const DECLARATION_STYLE: CSSProperties = {
  paddingTop: 35,
  paddingBottom: 35,
  background: '#f5f5f5',
  position: 'relative',
};

const AdBox: FunctionComponent<Props> = (props) => {
  const elementId = `adunit-${props.id.replace('/', '')}`;
  const slotName = useRef<string>(`${props.id}`);

  const boxRef = useRef<HTMLDivElement>(null);
  const isViewableObserver = useRef<IntersectionObserver | null>(null);
  const inDisplayRangeObserver = useRef<IntersectionObserver | null>(null);
  const inBidsRangeObserver = useRef<IntersectionObserver | null>(null);

  const display = useRef<boolean>(false);
  const lastInteractionTimeout = useRef<number | null>(null);
  const refreshTriggerTimeout = useRef<number | null>(null);
  const timedRefreshInterval = useRef<number | null>(null);
  const renderedHeight = useRef<number | null>(null);

  const isInDisplayRange = useRef<boolean>(false);
  const isViewable = useRef<boolean>(false);
  const isUserInactive = useRef<boolean>(false);
  const isInView = useRef<boolean>(false);

  const bidsPending = useRef<boolean>(false);
  const bidsTime = useRef<number>(0);
  const numberOfRefreshes = useRef<number>(0);
  const mouseOver = useRef<boolean>(false);
  const viewableStartTime = useRef<number | null>(null);
  const lastEnteredViewTime = useRef<number | null>(null);
  const totalTimeInView = useRef<number>(0);

  const recoveryMode = useRef<boolean>(false);
  const empty = useRef<boolean>(false);

  const getContainerStyle = (): CSSProperties => {
    if (props.declaration) {
      return {
        ...props.containerStyle,
        ...DECLARATION_STYLE,
      };
    }

    return { ...props.containerStyle };
  };

  const [containerStyle, setContainerStyle] = useState<CSSProperties>(getContainerStyle());
  const [contentStyle, setContentStyle] = useState<CSSProperties>({});

  /**
   * Get the total amount of time this ad has spent visible within the viewport.
   *
   * @returns total time in-view in milliseconds
   */
  const getTimeInView = () => {
    let time = totalTimeInView.current;
    if (lastEnteredViewTime.current != null) {
      time += new Date().getTime() - lastEnteredViewTime.current;
    }
    return time;
  };

  /**
   * Get the number of milliseconds since the ad became viewable.
   *
   * @returns time since viewable in milliseconds
   */
  const getTimeSinceViewable = () => {
    if (viewableStartTime.current != null) return new Date().getTime() - viewableStartTime.current;
    return 0;
  };

  /**
   * Determine if the ad box is currently valid for refresh:
   * - Must be viewable
   * - Must have less than MAX_REFRESHES
   * - Must have the `refresh` prop set to true or unspecified
   *
   * @returns true if the unit is valid for refresh
   */
  const isValidForRefresh = (timed?: boolean) => {
    return (
      numberOfRefreshes.current < MAX_REFRESHES &&
      isViewable.current &&
      !recoveryMode.current &&
      !mouseOver.current &&
      !isUserInactive.current &&
      getTimeInView() >= MIN_TIME_IN_VIEW &&
      getTimeSinceViewable() >= MIN_REFRESH_DELAY &&
      (!timed || getTimeSinceViewable() >= MIN_TIMED_REFRESH_DELAY) &&
      (props.refresh || false)
    );
  };

  /**
   * Determine if there is currently a bid available for the
   * ad unit that has not become stale.
   *
   * @returns true if no stale bids
   */
  const isBidFresh = () => {
    const earliestBidTime = new Date().getTime() - BIDS_TIMEOUT;

    return bidsTime.current >= earliestBidTime;
  };

  /**
   * Requests bids from the bidders if there is no
   * fresh bid currently available for the ad slot.
   */
  const requestBidsIfRequired = async () => {
    if (!isBidFresh()) {
      bidsTime.current = new Date().getTime();
      bidsPending.current = true;
      prepareUnit(props.config, slotName.current, elementId, props.sizes);
      await requestBids();
      bidsPending.current = false;
    }
  };

  /**
   * Actually refreshes the ad unit.
   */
  const refreshUnitIfEligable = (timed?: boolean) => {
    if (refreshTriggerTimeout.current != null) clearTimeout(refreshTriggerTimeout.current);
    if (isValidForRefresh(timed)) {
      refreshTriggerTimeout.current = window.setTimeout(async () => {
        numberOfRefreshes.current += 1;
        lastEnteredViewTime.current = new Date().getTime();
        viewableStartTime.current = null;
        removeUnit(slotName.current);
        prepareUnit(props.config, slotName.current, elementId, props.sizes);
        await requestBidsIfRequired();
        refreshUnit(slotName.current);
      }, 250);
    }
  };

  /**
   * Display the ad unit if it is valid to be displayed, i.e. it is
   * currently within the display range.
   */
  const displayAdsIfValid = async () => {
    if (isInDisplayRange.current) {
      if (isBidFresh()) {
        if (!bidsPending.current) {
          activateUnit(slotName.current);
        }
      } else {
        await requestBidsIfRequired();
        activateUnit(slotName.current);
      }
    }
  };

  const cancelTimedRefresh = () => {
    if (timedRefreshInterval.current != null) window.clearTimeout(timedRefreshInterval.current);
  };

  const beginTimedRefresh = () => {
    if (props.timedRefresh === true) {
      cancelTimedRefresh();
      timedRefreshInterval.current = window.setInterval(() => {
        refreshUnitIfEligable(true);
      }, 1000);
    }
  };

  const onMouseEnter = () => {
    mouseOver.current = true;
  };

  const onMouseLeave = () => {
    mouseOver.current = false;
  };

  const onInteraction = () => {
    isUserInactive.current = false;
    if (lastInteractionTimeout.current != null) clearTimeout(lastInteractionTimeout.current);
    lastInteractionTimeout.current = window.setTimeout(() => {
      isUserInactive.current = true;
    }, USER_IDLE_TIMEOUT);
  };

  const enterView = () => {
    if (isInView.current) {
      lastEnteredViewTime.current = new Date().getTime();
      beginTimedRefresh();
    }
  };

  const leaveView = () => {
    if (isInView.current) {
      cancelTimedRefresh();

      // Increment the in-view time when the ad leaves the viewport
      if (lastEnteredViewTime.current != null) {
        totalTimeInView.current += new Date().getTime() - lastEnteredViewTime.current;
        lastEnteredViewTime.current = null;
      }
    }
  };

  const onInView: IntersectionObserverCallback = (entries) => {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting) {
        isInView.current = true;
        enterView();
      } else {
        leaveView();
        isInView.current = false;
      }
    });
  };

  const onInDisplayRange: IntersectionObserverCallback = (entries) => {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting) {
        isInDisplayRange.current = true;
        displayAdsIfValid();
      } else {
        isInDisplayRange.current = false;
      }
    });
  };

  const onInBidsRange: IntersectionObserverCallback = (entries) => {
    entries.forEach(async (entry) => {
      if (entry.isIntersecting) {
        await requestBidsIfRequired();
        displayAdsIfValid();
      }
    });
  };

  const onWindowVsibilityChange = () => {
    if (document.visibilityState === 'visible') {
      enterView();
    } else {
      leaveView();
    }
  };

  const onViewable: any = (e: any) => {
    if (e.slot.getSlotElementId() === elementId) {
      isViewable.current = true;
      viewableStartTime.current = new Date().getTime();
    }
  };

  const isInterscrollerActive = (elementHeight: number) => {
    return !!(props.interscroller && !Number.isNaN(elementHeight));
  };

  /**
   * Get the correct container width for the ad to be rendered
   * if the ad is a 'fluid' ad then the width should be 100%, otherwise
   * it should be the maximum supported width of the slot.
   *
   * @returns the correct container width in CSS units
   */
  const getElementWidth = () => {
    if (props.sizes.indexOf('fluid') > -1) return '100%';
    let largestWidth = 0;
    props.sizes.forEach((value) => {
      if (Number(value[0]) > largestWidth) largestWidth = Number(value[0]);
    });
    return largestWidth;
  };

  /**
   * Get the correct container height for the ad to be rendered
   * which is the maximum supported height of the slot.
   *
   * @returns the correct container height in CSS units
   */
  const getElementHeight = () => {
    if (props.sizes.length === 1 && props.sizes[0] === 'fluid') return undefined;
    let largestHeight = 0;
    props.sizes.forEach((value) => {
      if (Number(value[1]) > largestHeight) largestHeight = Number(value[1]);
    });
    return largestHeight;
  };

  const handleThisSlotRendered = (event: any) => {
    if (event.isEmpty) {
      empty.current = true;
      if (props.recoveryUnitCode && !recoveryMode.current) {
        recoveryMode.current = true;
        removeUnit(slotName.current);
        registerUnit(props.recoveryUnitCode, slotName.current, elementId, props.sizes, false, {
          recovery: true,
        });
        activateUnit(slotName.current);
      } else {
        setContentStyle({
          height: '',
        });
      }
    } else if (event.slotContentChanged) {
      empty.current = false;
      const elementHeight = Number(getElementHeight());
      const renderedAdSize = Number(event.size[1]);
      const interscrollerStyle = props.interscrollerStyle || INTERSCROLLER_STYLE;
      renderedHeight.current = renderedAdSize;

      if (isInterscrollerActive(elementHeight)) {
        setContainerStyle({
          ...getContainerStyle(),
          ...props.renderedStyle,
          ...interscrollerStyle,
          height: INTERSCROLLER_CHANNEL_HEIGHT,
        });
        setContentStyle({
          position: 'sticky',
          height: '',
          top: INTERSCROLLER_PADDING + (props.interscrollerOffset || 0),
        });
      } else {
        setContainerStyle({
          ...getContainerStyle(),
          ...props.renderedStyle,
        });
        if (props.collapse) {
          setContentStyle({
            height: '',
          });
        }
      }
    }
  };

  const handleThisSlotLoad = useDebounce(
    () => {
      if (renderedHeight.current && renderedHeight.current <= 1 && !empty.current && props.collapse) {
        setContentStyle({
          height: '',
        });
      }
    },
    25,
    { leading: false, trailing: true },
    [props.collapse],
  );

  const onSlotRendered = (event: any) => {
    if (event.slot.getSlotElementId() === elementId) {
      handleThisSlotRendered(event);
    }
  };

  const onSlotLoad = (event: any) => {
    if (event.slot.getSlotElementId() === elementId) {
      handleThisSlotLoad();
    }
  };

  /**
   * Determine the appropriate slot name to use based on the migrationUnitCode and migrationPercentage
   * props.
   *
   * @returns the unit code for the adbox
   */
  const getSlotName = () => {
    if (props.abTestCode && props.abTestPercentage && props.abTestPercentage > 0) {
      if (Math.random() < props.abTestPercentage) return props.abTestCode;
    }
    return `${props.id}`;
  };

  useEffect(() => {
    if (props.obscured) {
      leaveView();
    } else {
      enterView();
    }
  }, [props.obscured]);

  useEffect(() => {
    // Reset state
    bidsPending.current = false;
    bidsTime.current = 0;
    numberOfRefreshes.current = 0;
    recoveryMode.current = false;
    empty.current = false;
    renderedHeight.current = null;
    isInDisplayRange.current = false;
    isViewable.current = false;
    isUserInactive.current = false;
    isInView.current = false;
    bidsPending.current = false;
    numberOfRefreshes.current = 0;
    viewableStartTime.current = null;
    lastEnteredViewTime.current = null;
    totalTimeInView.current = 0;

    slotName.current = getSlotName();

    onConsent(
      () => {
        display.current = true;

        const displayRange = props.displayMargin || DISPLAY_MARGIN;
        const bidsRange = displayRange + (props.bidsMargin || BIDS_MARGIN);

        inBidsRangeObserver.current = new IntersectionObserver(onInBidsRange, {
          rootMargin: `0px 0px ${bidsRange}px 0px`,
          threshold: 0.01,
        });

        inDisplayRangeObserver.current = new IntersectionObserver(onInDisplayRange, {
          rootMargin: `0px 0px ${displayRange}px 0px`,
          threshold: 0.01,
        });

        isViewableObserver.current = new IntersectionObserver(onInView, {
          rootMargin: `0px 0px 0px 0px`,
          threshold: 0.75,
        });

        addListener('impressionViewable', onViewable);
        addListener('slotRenderEnded', onSlotRendered);
        addListener('slotOnload', onSlotLoad);
        window.addEventListener(`visibilitychange`, onWindowVsibilityChange);

        if (boxRef.current) {
          inBidsRangeObserver.current.observe(boxRef.current);
          inDisplayRangeObserver.current.observe(boxRef.current);
          isViewableObserver.current.observe(boxRef.current);
        }
      },
      [],
      [],
    );

    return () => {
      if (display.current) {
        cancelTimedRefresh();
        removeUnit(slotName.current);
        if (refreshTriggerTimeout.current != null) clearTimeout(refreshTriggerTimeout.current);
        if (lastInteractionTimeout.current != null) clearTimeout(lastInteractionTimeout.current);
        removeListener('impressionViewable', onViewable);
        removeListener('slotRenderEnded', onSlotRendered);
        removeListener('slotOnload', onSlotLoad);

        window.removeEventListener(`visibilitychange`, onWindowVsibilityChange);

        inBidsRangeObserver.current?.disconnect();
        inDisplayRangeObserver.current?.disconnect();
        isViewableObserver.current?.disconnect();
      }
    };
  }, [props.id, props.url, props.sizes]);

  useEventListener('mousemove', onInteraction);
  useEventListener('click', onInteraction);
  useEventListener('keyup', onInteraction);
  useEventListener('scroll', onInteraction);
  useEventListener('touchstart', onInteraction);
  useEventListener('touchend', refreshUnitIfEligable);
  useEventListener('click', refreshUnitIfEligable);

  return (
    <Container
      className="hide-print"
      ref={boxRef}
      hideUnder={props.hideUnder}
      hideOver={props.hideOver}
      position={props.position}
      style={containerStyle}
      disabledPointerEvents={props.disabledPointerEvents}
      data-testid={props.testID}
    >
      {props.declaration && <Declaration>advertisement</Declaration>}
      <Content
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
        id={elementId}
        style={{
          maxWidth: getElementWidth(),
          height: getElementHeight(),
          ...contentStyle,
        }}
      />
    </Container>
  );
};

export default React.memo(AdBox);
