const rubiconParams = {
  accountId: 15656,
  siteId: 102986,
  zoneId: 483906,
};

const unrulyParams = {
  siteId: 258192,
};

export const liveRampParams = {
  placementId: '13953',
};

export const PBJS_BLOCKED_DOMAINS = [
  'propertynews.com',
  'zoopla.co.uk',
  'zoopla.com',
  'onthemarket.com',
  'rightmove.co.uk',
];

export const PBJS_CONFIG = {
  bidderSequence: 'random',
  enableSendAllBids: true,
  priceGranularity: 'auto',
  useBidCache: true,
  minBidCacheTTL: 0,
  enableTIDs: true,
  gvlMapping: {
    pairId: 755,
  },
  consentManagement: {
    gdpr: {
      cmpApi: 'iab',
      timeout: 8000,
      defaultGdprScope: true,
      rules: [
        {
          purpose: 'storage',
          enforcePurpose: true,
          enforceVendor: true,
          vendorExceptions: ['pubCommonId'],
        },
        {
          purpose: 'basicAds',
          enforcePurpose: true,
          enforceVendor: true,
        },
        {
          purpose: 'measurement',
          enforcePurpose: true,
          enforceVendor: true,
        },
      ],
    },
    usp: {
      timeout: 100,
    },
  },
  currency: {
    adServerCurrency: 'GBP',
  },
  rubicon: {
    singleRequest: true,
  },
  ix: {
    timeout: 500,
  },
  userSync: {
    iframeEnabled: true,
    filterSettings: {
      iframe: {
        bidders: ['ix', 'rubicon', 'sovrn'],
        filter: 'include',
      },
      image: {
        bidders: '*',
        filter: 'include',
      },
    },
    userIds: [
      {
        name: 'sharedId',
        storage: {
          type: 'cookie',
          name: '_sharedid',
          expires: 365,
        },
      },
      {
        name: 'identityLink',
        params: {
          pid: liveRampParams.placementId,
          notUse3P: false,
        },
        storage: {
          type: 'cookie',
          name: 'idl_env',
          expires: 15,
          refreshInSeconds: 1800,
        },
      },
      {
        name: 'pairId',
        params: {
          liveramp: {},
        },
      },
    ],
    syncDelay: 3000,
  },
  ortb2: {
    site: {
      name: 'PropertyPal.com',
      domain: 'www.propertypal.com',
      cat: ['IAB21'],
      sectioncat: ['IAB21-3'],
      pagecat: ['IAB21-3'],
      language: 'en',
      mobile: 1,
      privacypolicy: 1,
      publisher: {
        name: 'PropertyPal.com Ltd',
        cat: ['IAB21'],
        domain: 'www.propertypal.com',
      },
      keywords: 'real estate, houses, apartments, property sales, property lettings, rentals',
    },
  },
};

const PB_CONFIG_BASE = [
  {
    bidder: 'rubicon',
    params: {
      accountId: rubiconParams.accountId,
      siteId: rubiconParams.siteId,
      zoneId: rubiconParams.zoneId,
    },
  },
  {
    bidder: 'unruly',
    params: {
      siteId: unrulyParams.siteId,
    },
  },
];

const PB_CONFIG_WIDE = [
  ...PB_CONFIG_BASE,
  {
    bidder: 'sharethrough',
    params: {
      pkey: 'pLZwbW7btD23n41UfKmbEaqQ',
    },
  },
];

const PB_CONFIG_BOX = [
  ...PB_CONFIG_BASE,
  {
    bidder: 'sharethrough',
    params: {
      pkey: 'ZNY2z8egwj8yACegji78CLzy',
    },
  },
];

const PB_CONFIG_SMALL = [
  ...PB_CONFIG_BASE,
  {
    bidder: 'sharethrough',
    params: {
      pkey: 'RUyogJDMEndzXAb3xGrkghyd',
    },
  },
];

const PB_CONFIG_TALL = [
  ...PB_CONFIG_BASE,
  {
    bidder: 'sharethrough',
    params: {
      pkey: 'N56CsVbYM28gWtCPm7N1ZX47',
    },
  },
];

const SOVRN_CONFIG = (tagid: number) => ({
  bidder: 'sovrn',
  params: { tagid },
});

export const PREBID_UNIT_CONFIG = {
  SRPtop_desktop: [...PB_CONFIG_WIDE, SOVRN_CONFIG(1239434)],
  SRPtop_mobile: [...PB_CONFIG_BOX, SOVRN_CONFIG(1239435)],
  SRPright: [...PB_CONFIG_BOX, SOVRN_CONFIG(1239431)],
  SRPrightbottom: [...PB_CONFIG_TALL, SOVRN_CONFIG(1239430)],
  SRPmiddle_desktop: [...PB_CONFIG_WIDE, SOVRN_CONFIG(1239440)],
  SRPmiddle_mobile: [...PB_CONFIG_SMALL, SOVRN_CONFIG(1239433)],
  SRPbottom_desktop: [...PB_CONFIG_WIDE, SOVRN_CONFIG(1239436)],
  SRPbottom_mobile: [...PB_CONFIG_BOX, SOVRN_CONFIG(1239441)],
  SRPstickyfooter: [...PB_CONFIG_SMALL, SOVRN_CONFIG(1239438)],
  PDPtop: [...PB_CONFIG_SMALL, SOVRN_CONFIG(1239439)],
  PDPmiddle_desktop: [...PB_CONFIG_WIDE, SOVRN_CONFIG(1239442)],
  PDPmiddle_mobile: [...PB_CONFIG_BOX, SOVRN_CONFIG(1239443)],
  PDPmap: [...PB_CONFIG_SMALL, SOVRN_CONFIG(1239444)],
  PDPmediabox: [...PB_CONFIG_SMALL, SOVRN_CONFIG(1239445)],
};
