/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
const BID_TIMEOUT = 1000;

let apsInit = false;

export function initApsTag() {
  if (!apsInit) {
    apsInit = true;
    try {
      /* eslint-disable no-unused-expressions, object-shorthand, prefer-rest-params, func-names */
      !(function (a9, a, p, s, t, A, g) {
        if (a[a9]) return;
        function q(c, r) {
          a[a9]._Q.push([c, r]);
        }
        a[a9] = {
          init: function () {
            q('i', arguments);
          },
          fetchBids: function () {
            q('f', arguments);
          },
          setDisplayBids: function () {},
          targetingKeys: function () {
            return [];
          },
          _Q: [],
        };
        A = p.createElement(s);
        A.async = !0;
        A.src = t;
        g = p.getElementsByTagName(s)[0];
        g.parentNode.insertBefore(A, g);
      })('apstag', window, document, 'script', '//c.amazon-adsystem.com/aax2/apstag.js');
      /* eslint-enable no-unused-expressions, object-shorthand, prefer-rest-params, func-names */

      apstag.init({
        pubID: 'fc472d34-0da2-46e3-8eb9-7d48637c5a71',
        adServer: 'googletag',
      });

      return true;
    } catch (err) {
      return false;
    }
  }
  return false;
}

export const fetchApsBids = (slots) => {
  return new Promise((resolve) => {
    if (typeof apstag === 'undefined' || typeof apstag.fetchBids === 'undefined') {
      resolve(true);
    } else if (slots.length > 0) {
      try {
        apstag.fetchBids(
          {
            slots,
            timeout: BID_TIMEOUT,
          },
          () => {
            apstag.setDisplayBids();
            resolve(true);
          },
        );
      } catch (e) {
        resolve(true);
      }

      setTimeout(() => {
        resolve(true);
      }, BID_TIMEOUT + 200);
    } else {
      resolve(true);
    }
  });
};
