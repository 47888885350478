/* eslint-disable react/no-array-index-key */
import { Breadcrumb } from '@propertypal/shared/src/types/breadcrumbs';
import Link from 'next/link';
import router from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Chevron from '../icons/Chevron';
import { BackLink, Container, Crumb, Crumbs } from './Beadcrumb.style';

interface Props {
  crumbs?: Breadcrumb[];
  padding?: number;
  disableBack?: boolean;
}

const Breadcrumbs: FunctionComponent<Props> = (props) => {
  const [showBack, setShowBack] = useState(false);

  useEffect(() => {
    setShowBack(!props.disableBack && window.history.length > 1);
  }, []);

  return (
    <Container className="pp-breadcrumbs hide-print" data-testid="breadcrumbs" $padding={props.padding}>
      {props.crumbs && (
        <>
          {showBack && (
            <BackLink onClick={router.back}>
              <Chevron size={10} direction="left" /> Back
            </BackLink>
          )}

          <Crumbs itemScope itemType="http://schema.org/BreadcrumbList">
            <Crumb itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem">
              <Link
                href="/"
                prefetch={false}
                id="breadcrumb_home"
                itemScope
                itemProp="item"
                itemType="http://schema.org/ListItem"
                tabIndex={-1}
              >
                <span itemProp="name">Home</span>
              </Link>

              <Chevron containerStyle={{ marginLeft: 5 }} size={10} />

              <meta itemProp="position" content="1" />
            </Crumb>

            {props.crumbs.map((crumb, index, array) => {
              const isLast = index + 1 === array.length;

              if (isLast) {
                return <Crumb key={crumb.url}>{crumb.label}</Crumb>;
              }

              return (
                <Crumb key={index} itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem">
                  <Link
                    href={crumb.url}
                    id={`breadcrumb_${index + 1}`}
                    itemScope
                    itemProp="item"
                    itemType="http://schema.org/Thing"
                    tabIndex={-1}
                    prefetch={false}
                  >
                    <span itemProp="name">{crumb.label}</span>
                  </Link>

                  <Chevron containerStyle={{ marginLeft: 5 }} size={10} />

                  <meta itemProp="position" content={String(index + 2)} />
                </Crumb>
              );
            })}
          </Crumbs>
        </>
      )}
    </Container>
  );
};

export default React.memo(Breadcrumbs);
