/* eslint-disable no-undef */
import {
  liveRampParams,
  PBJS_BLOCKED_DOMAINS,
  PBJS_CONFIG,
  PREBID_UNIT_CONFIG,
} from '@propertypal/shared/src/constants/prebid';

const BID_TIMEOUT = 1000;

let prebidInit = false;

if (typeof window !== 'undefined') {
  window.pbjs = window.pbjs || {};
  window.pbjs.que = window.pbjs.que || [];
}

export function initPrebid() {
  if (!prebidInit) {
    prebidInit = true;
    try {
      pbjs.bidderSettings = {
        rubicon: {
          bidCpmAdjustment(bidCpm) {
            return bidCpm * 0.8;
          },
        },
        ix: {
          storageAllowed: true,
        },
      };

      pbjs.que.push(() => {
        pbjs.setConfig(PBJS_CONFIG);

        pbjs.enableAnalytics([
          {
            provider: 'atsAnalytics',
            options: {
              pid: liveRampParams.placementId,
            },
          },
        ]);
      });

      return true;
    } catch {
      return false;
    }
  }
  return false;
}

// Check the meta.advertiserDomains array to see if
//  any of the listed domains is included in the PBJS_BLOCKED_DOMAINS array
const isBlockedAd = (meta) => {
  if (meta && meta.advertiserDomains) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < meta.advertiserDomains.length; i++) {
      // Separate domain into segments (in case it includes a subdomain)
      const domainSegments = meta.advertiserDomains[i].toLowerCase().split('.');

      // Iterate over domainSegments, building the domain from end to start
      // This is done to check both full domain and potential subdomains
      // eslint-disable-next-line no-plusplus
      for (let j = domainSegments.length - 1; j >= 0; j--) {
        const domainToCheck = domainSegments.slice(j).join('.');
        if (PBJS_BLOCKED_DOMAINS.includes(domainToCheck)) {
          return true;
        }
      }
    }
  }
  return false;
};

export const fetchPrebid = (adUnitCodes) => {
  return new Promise((resolve) => {
    if (typeof pbjs === 'undefined' || typeof pbjs.que === 'undefined') {
      resolve(true);
    } else {
      pbjs.que.push(() => {
        try {
          pbjs.requestBids({
            timeout: BID_TIMEOUT,
            adUnitCodes,
            bidsBackHandler: (bids) => {
              // Block advertiser domains
              Object.entries(bids).forEach(([_, unitBids]) => {
                if (unitBids.bids) {
                  unitBids.bids.forEach((bid) => {
                    if (isBlockedAd(bid.meta)) {
                      pbjs.markWinningBidAsUsed({ adId: bid.adId });
                    }
                  });
                }
              });

              pbjs.setTargetingForGPTAsync(adUnitCodes);
              resolve(true);
            },
          });
        } catch (e) {
          resolve(true);
        }

        setTimeout(() => {
          resolve(true);
        }, BID_TIMEOUT + 200);
      });
    }
  });
};

export const pushPrebidSlot = (config, code, sizes) => {
  if (typeof pbjs === 'undefined') return;

  pbjs.que.push(() => {
    if (typeof pbjs.addAdUnits === 'undefined') return;
    pbjs.addAdUnits([
      {
        code,
        mediaTypes: {
          banner: {
            sizes: sizes.filter((size) => typeof size !== 'string'),
          },
        },
        ortb2Imp: {
          page: window && window.location ? window.location.toString() : undefined,
        },
        bids: PREBID_UNIT_CONFIG[config],
      },
    ]);
  });
};

export const removePrebidSlot = (code) => {
  if (typeof pbjs === 'undefined') return;
  pbjs.que.push(() => {
    if (typeof pbjs.removeAdUnit === 'undefined') return;
    pbjs.removeAdUnit(code);
  });
};
