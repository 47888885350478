import styled from 'styled-components';

export const Container = styled.div<{
  position?: string;
  hideUnder?: number;
  hideOver?: number;
  disabledPointerEvents?: boolean;
}>`
  width: 100%;
  position: ${(props) => props.position || 'relative'};
  z-index: 3;

  // These help prevent AdBoxes within a modal allowing scrolling on the body
  ${(props) =>
    props.disabledPointerEvents &&
    `
    iframe {
       overflow: hidden !important;
       pointer-events: none !important;
    }
  `}

  ${(props) =>
    props.hideUnder &&
    `
    @media (max-width: ${props.hideUnder}px) {
      display: none;
    }
  `}

  ${(props) =>
    props.hideOver &&
    `
    @media (min-width: ${props.hideOver}px) {
      display: none;
    }
  `}
`;

export const Content = styled.div`
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Declaration = styled.div`
  color: #999;
  font-size: 12px;
  text-align: center;
  padding-top: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  text-align: center;
`;
