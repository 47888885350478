/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import { getItem } from '@propertypal/shared/src/services/local.storage';
import config from '../../../../../app.config.json';

if (typeof window !== 'undefined') {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];
}

const _YB = {
  bm() {
    return Math.random() >= 0.1 ? '1' : '0';
  },
  ab() {
    return _YB.dool ? 'b' : `a${Math.floor(Math.random() * 10)}`;
  },
  dc() {
    return _YB.dool ? 'd' : `c${Math.floor(Math.random() * 20)}`;
  },
  mx() {
    return !_YB.dool ? 'x' : `m${Math.floor(Math.random() * 180)}`;
  },
  tt() {
    return `tt${Math.floor(Math.random() * 10)}`;
  },
  dool: Math.random() >= 0.1,
};

let googletagInit = false;
let pageLevelTargeting = {};

if (typeof window !== 'undefined') {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];
}

export function initGoogleTag() {
  if (!googletagInit) {
    googletagInit = true;
    try {
      googletag.cmd.push(() => {
        // For prebid & ad loading
        // this means that a `refresh()` has to be called
        // on each unit before it loads (handled via refreshQueue)
        googletag.pubads().disableInitialLoad();

        googletag.pubads().setTargeting('yb_bm', _YB.bm());

        // Set debug
        if (config.adsDebug) {
          googletag.pubads().setTargeting('Debug', 'true');
        } else {
          googletag.pubads().setTargeting('Debug', 'false');
        }

        googletag.enableServices();
      });

      return true;
    } catch {
      return false;
    }
  }
  return false;
}

export function setTargeting(updatedTargeting) {
  pageLevelTargeting = updatedTargeting;
}

export function addListener(event, fn) {
  if (typeof googletag === 'undefined') return;
  googletag.cmd.push(() => {
    if (typeof googletag.pubads().addEventListener === 'function') googletag.pubads().addEventListener(event, fn);
  });
}

export function removeListener(event, fn) {
  if (typeof googletag === 'undefined') return;
  googletag.cmd.push(() => {
    if (typeof googletag.pubads().addEventListener === 'function') googletag.pubads().removeEventListener(event, fn);
  });
}

export function setPrivacySettings(settings) {
  if (typeof googletag === 'undefined') return;
  googletag.cmd.push(() => {
    if (googletag.pubads().setPrivacySettings === 'function') googletag.pubads().setPrivacySettings(settings);
  });
}

export function addGoogleSlot(code, sizes, slotID, targeting, ybActive) {
  if (typeof googletag !== 'undefined' && typeof googletag.defineSlot !== 'function') return null;

  const slot = googletag.defineSlot(code, sizes, slotID);

  if (slot) {
    slot.addService(googletag.pubads());

    if (ybActive) {
      const yt = new Date();
      let ybTh = yt.getUTCHours() - 8;
      const ybTm = yt.getUTCMinutes();
      let ybWd = yt.getUTCDay();

      if (ybTh < 0) {
        ybTh = 24 + ybTh;
        ybWd -= 1;
      }

      if (ybWd < 0) {
        ybWd = 7 + ybWd;
      }

      // Set YieldBird parameters
      slot.setTargeting('yb_ab', _YB.ab());
      slot.setTargeting('yb_dc', _YB.dc());
      slot.setTargeting('yb_mx', _YB.mx());
      slot.setTargeting('yb_tt', _YB.tt());
      slot.setTargeting('yb_ff', `${Math.round(Math.random())}`);
      slot.setTargeting('yb_th', ybTh.toString());
      slot.setTargeting('yb_tm', ybTm.toString());
      slot.setTargeting('yb_wd', ybWd.toString());
      slot.setTargeting('yb_inactive', false);
    } else {
      slot.setTargeting('yb_inactive', true);
    }

    // Set buyer type
    const buyerStatus = getItem('buyingStatus');
    if (buyerStatus) {
      slot.setTargeting('BuyerType', buyerStatus.replace('"', ''));
    }

    // Update targeting
    Object.keys(pageLevelTargeting).forEach((key) => {
      slot.setTargeting(key, pageLevelTargeting[key]);
    });
    if (targeting) {
      Object.keys(targeting).forEach((key) => {
        slot.setTargeting(key, targeting[key]);
      });
    }

    googletag.display(slotID);
  }

  return slot;
}

export function refreshGoogleTags(refreshSlots) {
  googletag.pubads().refresh(refreshSlots);
}
